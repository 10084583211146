@import "../resources/variables"

.Container
  height: 140px
  display: flex
  align-items: center

.Title_Container
  width: fit-content
  min-width: 150px
  height: 100%
  margin-right: 60px
  display: flex
  justify-content: flex-start
  align-items: center
  position: relative
  &::after
    content: ""
    position: absolute
    top: 0
    right: -30px
    width: 2px
    height: 100%
    background-color: $cyan-light

.Title
  color: $white
  font-family: 'Font-Bold', sans-serif
  font-size: 20px
  text-align: center
  text-transform: uppercase

.Images_Container
  width: 100%
  height: 100%
  padding: 40px 20px
  display: flex
  column-gap: 30px
  align-items: center

.Item
  width: 100%
  height: 100%
  max-height: 70px
  display: flex
  justify-content: center
  align-items: center

.Image
  width: fit-content
  height: fit-content
  max-width: 100%
  max-height: 100%

@media (max-width: 1200px)
  .Title
    font-size: 18px

@media (max-width: 1024px)
  .Container
    height: 120px
  .Title_Container
    min-width: 130px
    margin-right: 60px
  .Title
    font-size: 16px

@media (max-width: 768px)
  .Container
    height: 100px
  .Title_Container
    min-width: 105px
    margin-right: 40px
    &::after
      right: -20px
  .Title
    font-size: 14px

@media (max-width: 576px)
  .Title
    font-size: 12px

@media (max-width: 425px)
  .Title_Container
    width: fit-content
    min-width: 90px
    margin-right: 40px
    &::after
      right: -20px
  .Title
    font-size: 10px