$gray-dark: #3A3A3A
$gray: #636363
$gray-light: #B0B0B0
$white-dark: #e9e9e9
$white: #FFFFFF
$cyan-dark: #368290
$cyan: #419FB1
$cyan-light: #55CBD8
$magenta-dark: #d2296d
$magenta: #F73181
$orange: #FF9A2E