@import "./resources/variables"

@font-face
  font-family: 'Font-Regular'
  font-style: normal
  font-weight: 300
  // src: url('../assets/fonts/HelveticaNowText-Regular.woff2') format('woff2')
  // src: url('../assets/fonts/HelveticaNowText-Regular.woff') format('woff')
  // src: url('../assets/fonts/HelveticaNowText-Regular.eot') format('embedded-opentype')
  src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype')

@font-face
  font-family: 'Font-Bold'
  font-style: normal
  font-weight: 700
  // src: url('../assets/fonts/HelveticaNowText-Bold.woff2') format('woff2')
  // src: url('../assets/fonts/HelveticaNowText-Bold.woff') format('woff')
  // src: url('../assets/fonts/HelveticaNowText-Bold.eot') format('embedded-opentype')
  src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype')

@font-face
  font-family: 'Font-Condensed-Regular'
  font-style: normal
  font-weight: 300
  // src: url('../assets/fonts/D-DINCondensed.woff2') format('woff2')
  // src: url('../assets/fonts/D-DINCondensed.woff') format('woff')
  // src: url('../assets/fonts/D-DINCondensed.otf') format('opentype')
  // src: url('../assets/fonts/D-DINCondensed.ttf') format('truetype')
    src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype')

@font-face
  font-family: 'Font-Condensed-Bold'
  font-style: normal
  font-weight: 700
  // src: url('../assets/fonts/D-DINCondensed-Bold.woff2') format('woff2')
  // src: url('../assets/fonts/D-DINCondensed-Bold.woff') format('woff')
  // src: url('../assets/fonts/D-DINCondensed-Bold.otf') format('opentype')
  // src: url('../assets/fonts/D-DINCondensed-Bold.ttf') format('truetype')
  src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype')

*
  box-sizing: border-box
  transition: background-color .15s, color .15s, opacity .15s
  scroll-behavior: smooth

html, body
  padding: 0
  margin: 0
  border: 0
  font-family: "Font-Regular", sans-serif
  color: $gray


a
  display: inline-block
  color: inherit
  text-decoration: none
  color: $white
  cursor: pointer
  // &:hover
  //   color: $gray-light

button
  border: unset
  cursor: pointer

h1, h2, h3, h4, h5, h6
  font-family: 'Font-Condensed-Bold', sans-serif

h2
  margin: 1rem 0 !important

main
  overflow: hidden

.swiper-wrapper
  align-items: center

#g-recaptcha-response
  display: block !important
  position: absolute
  margin: -78px 0 0 0 !important
  width: 302px !important
  height: 76px !important
  z-index: -999999
  opacity: 0