@import "../resources/variables"

.scrollH
  box-shadow: 0 6px 6px hsla(0, 0%, 4%, 0.3)

.Container_Fluid
  background-color: $gray-dark
  position: fixed
  top: 0
  z-index: 10000
  transition: all 0.5s ease

.Container
  min-height: 80px
  height: fit-content
  padding: 0 20px
  display: flex
  justify-content: space-between
  align-items: center

.Logo_Container
  height: 30px

.Link_Container
  display: grid
  grid-template-columns: repeat(2, auto)
  column-gap: 10px

.Icon
  width: fit-content !important
  height: 30px !important

.Logo
  width: fit-content !important
  height: 30px !important

.Nav
  display: grid
  grid-template-columns: repeat(4, auto)
  column-gap: 40px



.Link
  color: $white
  letter-spacing: 1px
  text-transform: uppercase
  font-size: 14px
  font-family: "Font-Bold", sans-serif
  padding: 1rem 0 1rem 0

.Service_Button
  @extend .Link
  // padding: unset
  background: unset
  position: relative

.Services_Links
  width: max-content
  border-radius: 5px
  display: flex
  flex-direction: column
  position: absolute
  top: calc(100% )
  left: -1rem
  overflow: hidden
  transition: all 0.3s ease


.Services_Links_Hidde
  @extend .Services_Links
  // display: none 
  opacity: 0
  pointer-events: none
  


.Service_Link
  @extend .Link
  width: 100%
  padding: 16px
  background: $gray
  display: inline-block
  text-align: left
  text-transform: capitalize
  font-family: "Font-Regular", sans-serif
  &:hover
    background: $cyan-light
    color: $gray-dark

.Button
  padding: unset
  background: unset
  display: flex
  justify-content: center
  align-items: center
  color: $white
  font-size: 24px
  &:hover
    color: $white-dark
  img
    width: 24px

@media (max-width: 768px)
  .Container
    padding: 0 16px
    flex-direction: column
    align-items: flex-start
  .Logo_Container
    width: 100%
    height: 80px
    padding: 10px 0
    display: flex
    justify-content: center
    align-items: center
    column-gap: 0
    position: relative
  .Logo
    display: none !important
  .Nav
    width: 100%
    display: flex
    flex-direction: column
    &::after
      content: ''
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      background: linear-gradient(0deg, #1F1F1F 0%, #2C2C2C 3.12%, #303030 6.77%, #3A3A3A 20.31%)
      z-index: -1
    :last-child::after
      content: unset
  .Link_Container
    display: inline-block
  .Link
    padding: 16px 0
    position: relative
    font-size: 18px
    font-family: "Font-Regular", sans-serif
    text-transform: capitalize
    &::after
      content: ''
      width: 100%
      height: 1px
      background-color: $white
      position: absolute
      bottom: 0
      left: 0
      right: 0
  .Button
    display: block
    position: absolute
    top: 28px
    left: 0
  .Service_Button
    flex-direction: column
    align-items: flex-start
    text-align: left
  .Services_Links
    margin-top: 16px
    margin-left: 40px
    display: flex
    flex-direction: column
    position: static
    overflow: unset
  .Services_Links_Hidde
    display: none
  .Service_Link
    background: unset
    color: $cyan-light
    &::after
      content: unset
    &::before
      content: url('../resources/images/menu-button-detail.png')
      width: 5px
      height: 5px
      position: absolute
      top: calc(50% - 12px)
      left: -15px
    &:hover
      background: unset
      color: $cyan-dark