@import '../resources/variables'

.Hero_Container_Fluid
  background-image: url('../resources/images/service-background.png')
  background-repeat: no-repeat
  background-size: cover
  background-position: center
  position: relative

.three
  display: block
  width: 100vw
  height: 100vh

.Hero
  margin-top: 80px
  // min-height: 100vh
  // min-width: 100vw
  color: $white
 
  &_Row
    min-height: 100vh
    display: flex
    flex-direction: row
    align-items: center
  &_Title
    margin-top: 0
    font-size: 60px
  &_Text
    font-size: 30px  
  &_Image_Container
    display: flex
    justify-content: flex-end
  &_Image
    width: 50%    
  &_Bottom_Detail
    position: absolute
    top: 100%
    left: calc(50% - 17px)
    z-index: 1

.Item_Container
  position: relative

.Item
  &_Card
    display: flex
    align-items: center
    position: relative
    &_Left
      @extend .Item_Card
      justify-content: flex-start
      .Item_Text_Container
        margin-left: -5%
    &_Right
      @extend .Item_Card
      justify-content: flex-end
      .Item_Text_Container
        margin-right: -5%
    &_Unique
      @extend .Item_Card
      justify-content: center
      .Item_Text_Container
        margin-left: -5%
      .Item_Image
        aspect-ratio: 3 / 5
        width: 40%
        max-width: 480px        
  &_Image
    aspect-ratio: 90 / 49
    width: 65%
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.35)
    border-radius: 10px
  &_Text_Container
    width: 40%
    border-radius: 10px
    padding: 16px 30px
    background-color: $white
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.35)
    z-index: 10
  &_Title
    margin: 0
    color: $cyan
    font-size: 30px
  &_Text
    font-size: 20px

.Extra_Container_Fluid
  background: linear-gradient(180deg, #1F1F1F 0%, #2C2C2C 3.65%, #3A3A3A 30%)

.Extra
  min-height: 100vh
  color: $white
  &_Caption
    margin-top: 0
    font-size: 50px
    text-align: center
    &_Highlight
      color: $cyan-light
  &_Image_Container
    aspect-ratio: 1/1
    width: 30% !important
    height: min-content
    margin: 0 auto
    padding: 0 !important
    align-self: center
    position: relative
    z-index: 2
  &_Image
    width: 90%
    &_Back
      width: 90%
      opacity: .1
      filter: blur(3px)
      position: absolute
      bottom: 0
      right: 0
      z-index: -1
  &_Row
    justify-content: space-between
  &_Text_Container
    display: flex
    flex-direction: column
    justify-content: space-evenly
  &_Item
    margin-bottom: 25px
    display: flex
    align-items: center
    column-gap: 25px
  &_Number
    width: 50px
    min-width: 50px
    height: 50px
    border-radius: 10px
    background: $cyan-light
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25)
    display: flex
    justify-content: center
    align-items: center
    font-size: 28px
  &_Text
    margin: 0
    font-size: 20px

@media (max-width: 992px)
  .Hero
    &_Title
      font-size: 45px
    &_Text
      margin: 0
      font-size: 25px
    &_Image
      width: 70%
  .Item
    &_Card
      &_Left
        .Item_Text_Container
          margin-left: -35%
      &_Right
        .Item_Text_Container
          margin-right: -35%
    &_Image
      width: 85%
    &_Text_Container
      width: 50%
      padding: 14px 26px
    &_Title
      font-size: 22px
    &_Text
      font-size: 16px
  .Extra
    &_Caption
      font-size: 45px
    &_Number
      width: 40px
      min-width: 40px
      height: 40px
      font-size: 24px
    &_Text
      font-size: 18px
    &_Image_Container
      aspect-ratio: 1/1
      width: 60% !important

@media (max-width: 768px)
  .Hero
    &_Row
      flex-direction: column
      justify-content: center
      align-items: center    
    &_Image_Container
      height: fit-content
      margin-bottom: 3rem
      padding: 0 5rem
      justify-content: flex-start
    // &_Text
    //   margin-top: 3rem
    &_Image
      width: unset
      height: 180px
  .Item
    &_Card
      &_Left
        flex-direction: column
        .Item_Text_Container
          margin-left: unset
          margin-top: -5%
      &_Right
        flex-direction: column-reverse
        .Item_Text_Container
          margin-right: unset
          margin-top: -5%
      &_Unique
        flex-direction: column
        justify-content: center
        .Item_Text_Container
          margin-left: unset
          margin-top: -5%
        .Item_Image
          aspect-ratio: 3 / 5
          width: 60%
    &_Image
      width: 100%
    &_Text_Container
      width: 80%
  .Extra
    &_Image_Container
      width: 70%
      margin-bottom: 40px

@media (max-width: 576px)
  .Hero
    &_Title
      font-size: 30px
    &_Text
      font-size: 20px
    &_Image
      height: 130px
    
  .Item
    &_Text_Container
      padding: 14px 20px
    &_Title
      font-size: 20px
    &_Text
      font-size: 16px
  .Extra
    &_Caption
      font-size: 30px
    &_Number
      font-size: 20px
    &_Text
      font-size: 16px