@import '../resources/variables'

.main
  background-color: $gray

.Hero_Container_Fluid
  background: linear-gradient(0deg, #1F1F1F 0%, #2C2C2C 3.12%, #303030 6.77%, #3A3A3A 20.31%)

.Hero
  min-height: 50vh
  margin-top: 80px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  &_Title
    margin-top: 0
    color: $cyan-light
    font-size: 60px
  &_Text
    max-width: 1010px
    margin: 0 5%
    color: $white
    font-size: 30px

.Project_Cards_Container
  color: $white
  :nth-child(odd) .Project_Title_Container
    background-color: $cyan
  :nth-child(even) .Project_Title_Container
    background-color: $gray
    display: grid

.Project
  &_Card
    width: 100% 
    height: 100%   
    aspect-ratio: 12 / 11
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    position: relative
    transition: all 1s ease
    cursor: pointer
    &:hover
      filter: contrast(110%) brightness(110%)
      .Project_Image
        filter: drop-shadow(0px 8px 15px black)
        
      // color: $white
      // opacity: .65 !important
    // &_Link
    //   transition: opacity .3s
    //   &:hover
    //     color: $white
    //     opacity: .65 !important
  &_Image
    width: 100% !important  
    transition: all 1s ease
  &_Topic_Container
    width: 120px
    border-radius: 0px 0px 0px 30px
    padding: 15px 20px
    background-color: $white
    position: absolute
    top: 0
    right: 0
    text-align: center
  &_Topic
    color: $cyan
    font-family: 'Font-Condensed-Bold', sans-serif
    font-size: 12px
    line-height: 0.5
    text-align: center
  &_Title_Container
    height: 20%
    width: 100%    
    display: flex
    justify-content: center
    align-items: center
  &_Title
    margin: 0
    font-family: 'Font-Condensed-Bold', sans-serif
    text-align: center
    font-size: 28px
    font-weight: 300

.Companies
  background: linear-gradient(180deg, #1F1F1F 0%, #2C2C2C 3.65%, #3A3A3A 29.69%)

@media (max-width: 1230px)
  .Project
    &_Title_Container
      height: 35%

@media (max-width: 1024px)
  .Hero
    &_Title
      font-size: 45px
    &_Text
      margin: 0
      font-size: 25px
    .Project
      &_Title_Container
        height: 30%

@media (max-width: 576px)
  .Hero
    align-items: flex-start
    text-align: left
    &_Title
      margin-bottom: 1rem
      font-size: 30px
    &_Text
      font-size: 20px
  