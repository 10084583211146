@import '../resources/variables'

.Form
  width: 50%
  max-width: 700px
  border: 3px solid $white
  border-radius: 14px
  padding: 24px 30px
  background: #1A435A73
  display: flex
  flex-direction: column
  justify-content: center
 
.Input_Double
  display: flex
  column-gap: 20px

.Input
  width: 100%
  height: 50px
  margin-bottom: 20px
  border: unset
  border-radius: 5px
  padding: 16px
  box-shadow: 3px 4px 4px 1px rgba(0, 0, 0, 0.16)
  font-size: 14px
  &:hover
    background-color: $white-dark
  &:focus-visible
    outline: 2px solid $cyan-light
  &::placeholder
    color: #B0B0B0

.Textarea
  @extend .Input
  height: auto
  font-family: 'Font-Regular', sans-serif
  font-size: 14px
  resize: none

.Button
  height: 50px
  border-radius: 5px
  background-color: $magenta
  box-shadow: 3px 4px 4px 1px rgba(0, 0, 0, 0.16)
  color: $white
  text-transform: uppercase
  font-family: 'Font-Bold', sans-serif
  font-size: 16px
  &:hover
    background-color: $magenta-dark

.Recaptcha
  margin-bottom: 20px
  display: flex
  justify-content: center
  align-items: center

@media (max-width: 1024px)
  .Form
    padding: 16px 20px

@media (max-width: 768px)
  .Form
    margin-bottom: 30px

@media (max-width: 576px)
  .Form
    margin-bottom: 30px
  .Input_Double
    display: flex
    flex-direction: column
  .Input
    margin-bottom: 10px
  .Recaptcha
    margin-bottom: 10px